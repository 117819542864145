<template>
  <b-container fluid="">
    <b-row>
      <b-col sm="12" lg="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Tambah Satuan Pengeluaran</h4>
          </template>
          <template v-slot:body>
            <form >
              <div class="form-row">
                <b-col md="12" class="mb-3">
                  <label for="nip">Nama Satuan<strong>*</strong></label>
                  <input type="text" class="form-control" id="name" v-model="form.name">
                </b-col>
              </div>
              <div class="float-right">
                <button type="submit" class="btn bg-danger mr-1" @click.prevent="formCancel()">Batal</button>
                <button type="submit" class="btn btn-primary" @click.prevent="formSave()">Simpan</button>
              </div>
            </form>
          </template>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { postDataService } from '../../../store/modules/crudservices'

export default {
  name:'SatuanPengeluaranAdd',
  data() {
    return {
      form:{
        name: null
      }
    }
  },
  components:{
    
  },
  methods: {
    formSave(){
      const form = this.form
			const _data = {
				'name': form.name
			}
      
      postDataService('/satuan', _data).then(response => {
        if(response.data.success) this.$router.push('/pendukung/satuan')
      })
    },
    formCancel(){
      this.$router.push('/pendukung/satuan') 
    },
  }
}
</script>